.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.element {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: var(--color-neutral-4);
}

.selected {
  background: var(--color-primary-6);
}
