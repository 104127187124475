.wrapper {
  position: relative;
  padding: 16px;
  gap: 32px;
  background-color: var(--color-neutral-1);

  > svg {
    margin-bottom: 0;
  }

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3,
    h4,
    h5 {
      text-align: center;
    }

    h3 {
      margin: 16px 0 12px !important;
    }
  }
}

.withoutSupport {
  gap: 72px;
}

.actionsWrapper {
  position: fixed;
  z-index: 1;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  background: var(--color-neutral-1);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: fit-content;
  padding: 16px 16px calc(16px + var(--safe-area-bottom));
  border: none;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.iconWrapper {
  width: 64px;
  height: 64px;
  background: var(--color-warning);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  &.danger {
    background: var(--color-error);
  }

  svg {
    height: 48px;
    width: 48px;
    color: var(--color-neutral-1);
  }
}

.supportWrapper {
  margin-bottom: 36px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.supportModalWrapper {
  transition: 0.3s;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.55);
  z-index: 5;
}

.supportModal {
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  height: fit-content;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
  padding: 4px 16px 20px;
  background-color: var(--color-neutral-1);
  z-index: 10;
}

.modalCloseIcon {
  position: absolute;
  right: 16px;
  top: 16px;

  svg {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
  }
}

.supportModalActions {
  width: 100%;
  margin-top: 12px;
  margin-bottom: var(--safe-area-bottom);
}

.support {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  width: 100%;
  padding: 16px;
  border-radius: 16px;
  background: var(--color-neutral-2);

  > div {
    h4 {
      text-align: left;
    }
  }
}

.supportIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  background: var(--color-primary-6);
  border-radius: 50%;

  svg {
    height: 24px;
    width: 24px;
    color: var(--color-neutral-1);
  }
}

.secondText {
  padding-top: 32px;
  text-align: center;
}

.loginButton {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-primary-6);
  background: transparent;
  border: none;
}
