.wrapper {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px -1px 3px 0px rgba(0, 0, 0, 0.2);
  background: var(--color-neutral-1);
  padding: 9px 9px calc(9px + var(--safe-area-bottom));

  a {
    flex: 0 0 20%;
    position: relative;
    color: var(--color-character-secondary-45);
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    text-decoration: none;

    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      z-index: 2;
    }

    svg {
      z-index: 2;
      height: 24px;
      width: 24px;
    }
  }
}

.active {
  color: var(--color-primary-6) !important;

  > :first-child {
    visibility: visible;
    height: 64px;
    width: 64px;
    border-radius: 50%;
    background: var(--color-neutral-1);
    filter: drop-shadow(0px -4px 1px rgba(0, 0, 0, 0.05));
  }
}

.halo {
  position: absolute;
  top: -18px;
  visibility: hidden;
}
