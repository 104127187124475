.keyboard {
  width: 100%;
  padding: 0 24px var(--safe-area-bottom);
}

.codeWithLabel {
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  gap: 30px;
}

.logoWithCodeWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 100px;
}

.backspace {
  height: 24px;
  width: 24px;
  color: var(--color-character-secondary-45);
}

.passwordsAreMatched {
  background: var(--color-success);
}

.passwordsAreWrong {
  background: var(--color-error);
}

.check {
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 64px;
  border-radius: 50%;
  background: var(--color-success);

  svg {
    color: var(--color-neutral-1);
    height: 48px;
    width: 48px;
  }
}

.topLogoContainer {
  justify-content: space-between;

  svg {
    margin-bottom: 0;
  }
}

.error {
  position: absolute;
  bottom: -53px;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
}
