.wrapper {
  position: absolute;
  padding: 0;
  border: none;
  background: transparent;
  right: 16px;
  color: var(--color-primary-6);
  text-decoration: none;

  &:hover {
    svg {
      color: var(--color-primary-5);
    }
  }

  svg {
    color: var(--color-primary-6);
    height: 24px;
    width: 24px;
  }
}
