.wrapper {
  background: rgba(0, 0, 0, 0.55);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
}

.content {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: #FFF;
  border-radius: 20px 20px 0 0;
  padding: 16px;
}

.form {
  margin-top: 8px;
}
