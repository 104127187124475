.wrapper {
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 8px;

  span {
    margin-top: 2px;
  }

  svg {
    flex-shrink: 0;
    height: 20px;
    width: 20px;
  }
}

.info {
  svg {
    color: var(--color-primary-6);
  }
}

.error {
  svg {
    color: var(--color-error);
  }
}
