.wrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 0 !important;
  white-space: nowrap;
  font-weight: 500 !important;
  max-width: 70svw;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
