.wrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 24px;
    width: 83px;
  }
}
