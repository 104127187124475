.wrapper {
  padding: 32px;

  span {
    text-align: center;
  }
}

.content {
  height: 100%;
}

.icon {
  flex-shrink: 0;
  padding: 8px;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  color: var(--color-primary-1);
  background-color: var(--color-warning);
}

.logout {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: none;
  background: transparent;
  padding: 24px 16px;

  > svg,
  span {
    color: var(--color-primary-6) !important;
  }
}
