.wrapper {
  justify-content: space-between;

  svg {
    margin-bottom: 0;
  }
}

.backspace {
  height: 24px;
  width: 24px;
  color: var(--color-character-secondary-45);
}

.faceId {
  height: 22px;
  width: 22px;
  color: var(--color-character-secondary-45);
}

.touchId {
  height: 24px;
  width: 24px;
  color: var(--color-character-secondary-45);
}

.keyboard {
  width: 100%;
  padding-bottom: var(--safe-area-bottom);
}

.exit {
  span {
    color: var(--color-character-secondary-45);
    font-size: 14px;
  }
}

.codeWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.passwordsAreMatched {
  background: var(--color-success);
}

.passwordsAreWrong {
  background: var(--color-error);
}
