@import 'main';
@import 'variables';

*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
  font-family: 'Roboto', sans-serif;
}

html {
  height: 100%;
  overflow: hidden;
}

body {
  height: 100%;
  overflow: hidden;
}

#root {
  height: 100%;
}

a {
  :focus {
    background: transparent;
  }
  ::selection {
    background: transparent;
  }
}

* {
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
}

::-webkit-scrollbar {
  display: none;
}

textarea {
  font-family: 'Roboto', sans-serif !important;
  font-size: 16px !important;
}

iframe {
  display: none;
}
