.wrapper {
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-primary-6);
  text-decoration: none;

  &:hover {
    color: var(--color-primary-5);
  }
}
