.icon {
  position: relative;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-neutral-1);
  background: var(--color-primary-6);
  border-radius: 50%;
  flex-shrink: 0;

  svg {
    height: 24px;
    width: 24px;
  }

  &.icon300 {
    background: rgba(238, 202, 95, 1);

    svg {
      color: rgba(221, 177, 44, 1);
    }
  }

  &.icon700 {
    background: rgba(217, 217, 217, 1);

    svg {
      color: rgba(250, 250, 250, 1);
    }
  }

  &.icon1000 {
    background: rgba(230, 247, 255, 1);

    svg {
      color: rgba(0, 109, 182, 1);
    }
  }
}

.top {
  color: var(--color-primary-6) !important;
}

.tags {
  padding: 16px;
  border-bottom: 1px solid var(--color-neutral-4);
}

.download {
  padding: 12px 16px;
  border-bottom: 1px solid var(--color-neutral-4);

  .icon {
    background: rgba(230, 247, 255, 1);

    svg {
      color: rgba(0, 109, 182, 1);
    }
  }
}

.tag {
  height: 34px;
  font-size: 14px;
  padding: 0 14px;
  display: flex;
  align-items: center;
}

.memo {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.contacts {
  padding: 16px;
}

.paddingLeft {
  padding-left: 10px;
}
