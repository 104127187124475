.wrapper {
  background: var(--color-neutral-1);
  width: 100%;
  padding: 16px;
  border-radius: 20px 20px 0 0;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);

  gap: 12px;

  h3 {
    text-align: center;
    padding: 18px 0px;
    font-weight: 500;
    margin-bottom: 4px;
  }
}

.buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  margin-bottom: var(--safe-area-bottom);
}
