.first_time_container {
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  gap: 8px;

  .text {
    display: grid;
    gap: 4px;
  }

  .first_time_icon {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    height: 48px;
    width: 48px;
    border-radius: 50%;

    svg {
      color: var(--color-neutral-1);
      height: 23px;
      width: 23px;
    }
  }
}
