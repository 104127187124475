@import 'src/styles/variables';

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding-top: var(--safe-area-top);
}

.header {
  justify-content: space-between;
}

.navigation {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: calc(56px + var(--safe-area-bottom));
}

.content {
  height: calc(100% - $header-height - $navbar-height);
  padding-bottom: var(--safe-area-bottom);
}

.contentWithoutNavbar {
  height: calc(100% - $header-height);
  padding-bottom: var(--safe-area-bottom);
}
