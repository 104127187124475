$fix-error-card-height: 80px;

.wrapper {
  position: fixed;
  z-index: 1;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  background: var(--color-neutral-1);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: $fix-error-card-height;
  padding: 16px;
  border: none;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
}
