.icon {
  position: relative;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-neutral-1);
  background: var(--color-primary-6);
  border-radius: 50%;
  flex-shrink: 0;

  svg {
    height: 24px;
    width: 24px;
  }
}

.download {
  padding: 12px 16px;
  border-bottom: 1px solid var(--color-neutral-4);

  .icon {
    background: rgba(230, 247, 255, 1);

    svg {
      color: rgba(0, 109, 182, 1);
    }
  }
}

.contacts {
  padding: 16px;
}
