.faceIdConnect {
  color: var(--color-primary-6);
  margin-bottom: 16px;
}

.faceIdConnected {
  color: var(--color-success);
  margin-bottom: 16px;
}

.faceIdDescription {
  text-align: center;
  margin-top: 12px;
}

.actonWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: var(--safe-area-bottom);
}

.textWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 32px;
  text-align: center;

  svg {
    margin-bottom: 16px !important;
  }
}
