.wrapper {
  width: 100%;
  height: 100%;
  background: var(--color-neutral-2);
  padding: 16px 8px;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
