.wrapper {
  background: var(--color-neutral-1);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  padding: 8px 16px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 2;
}
