.container {
  position: absolute;
  right: 16px;
  height: max-content;
  width: max-content;
}
.wrapper {
  color: var(--color-primary-6);
  text-decoration: none;

  &:hover {
    color: var(--color-primary-5);
  }
}
