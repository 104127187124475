.loader {
  width: 90px;
  height: 90px;
  border: 4px solid #ccc;
  border-radius: 50%;
  border-top: 4px solid var(--color-primary-6);
  border-right: 4px solid var(--color-primary-6);
  animation: spin 1s linear infinite;
  flex-shrink: 0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.size20 {
  width: 20px;
  height: 20px;
}

.size24 {
  width: 24px;
  height: 24px;
}
