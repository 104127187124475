.form {
  background: var(--color-neutral-1);
  width: 100%;
  padding: 16px;
  border-radius: 20px 20px 0 0;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);

  div {
    & label span > span {
      display: inline;
      font-size: 16px;
    }
    :global {
      .ant-checkbox-wrapper {
        align-items: flex-start !important;

        .ant-checkbox {
          align-self: start !important;
          position: relative;
          top: 3px;
        }
      }
    }
  }

  button {
    margin-bottom: var(--safe-area-bottom);
  }

  h4 {
    margin: 0;
  }

  :global {
    .ant-typography a:hover {
      color: var(--color-character-title-85);
      text-decoration: underline;
    }
  }
}

.checkboxTrans {
  a {
    color: var(--color-primary-6);
    text-decoration: none;
  }
}
