.wrapper {
  display: block;
  max-width: 600px;
  margin: 0 auto;

  > button {
    height: 60px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 33.3333%;
    border: none;
    background: transparent;
    position: relative;

    > span {
      display: flex;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }
}

.withLeftElement {
  justify-content: flex-start;
}
.withRightElement {
  justify-content: flex-end;
}

.invisible {
  visibility: hidden;
  pointer-events: none;
}
