.check {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background: rgba(83, 182, 117, 1);

  svg {
    color: var(--color-neutral-1);
    height: 48px;
    width: 48px;
  }
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.inner {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 12px;
  padding: 0px 16px;
  justify-content: center;
}

.button {
  margin-top: 100px;
}
