.wrapper {
  svg {
    color: var(--color-primary-6);
  }
}

.ratingSvg {
  background-color: var(--color-primary-2);
  border-radius: 50%;
  height: 48px !important;
  width: 48px !important;

  svg {
    height: 32px !important;
    width: 32px !important;
  }
}
