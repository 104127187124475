.wrapper {
  background: var(--color-gradient-primary);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  padding-top: var(--safe-area-top);

  > :first-child {
    h3 {
      color: var(--color-neutral-1);
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.logoWithLine {
  margin: 12% 0 22%;
  position: relative;

  > svg {
    width: 100%;
  }

  > div {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    height: 100px;
    width: 100px;
    background: var(--color-neutral-1);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 11px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);

    > svg {
      height: 60px;
    }
  }
}

.gsp5 {
  margin-top: 8%;
  margin-bottom: 12px;
  width: 146px;
  color: var(--color-neutral-1);
}
