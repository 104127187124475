.container {
  background: var(--color-neutral-1);
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
  border-radius: 20px;

  & > div {
    margin: 16px;
  }
}

.segmentOption {
  display: flex;
  gap: 2px;
}

.list {
  margin: 16px 0 !important;
}
