.container {
  width: max-content;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  padding: 4px 8px;
  border-radius: 20px;
  align-items: center;
  gap: 5px;

  &.completed {
    border: 1px solid var(--color-neutral-5);

    > * {
      color: var(--color-success) !important;
    }
  }

  &.mandatory {
    background-color: var(--color-error);
    border: none;

    > * {
      color: var(--color-neutral-1) !important;
    }
  }
}
