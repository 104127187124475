.surveyCard {
  text-decoration: none;
  display: grid;
  gap: 12px;
  padding: 16px;

  .titleContainer {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    gap: 20px;

    svg {
      color: var(--color-primary-6);
    }
  }
}
