.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;

  > :first-child {
    display: flex;
    flex-direction: column;
    gap: 4px;
    text-align: left;
  }

  > :last-child {
    position: relative;
    height: 48px;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-neutral-1);
    background: var(--color-primary-6);
    border-radius: 50%;
    flex-shrink: 0;

    svg {
      height: 100%;
      width: 100%;
      color: var(--color-neutral-1);
    }
  }
}

.padding8 {
  padding: 8px;
}

.padding12 {
  padding: 12px;
}
