.wrapper {
  background: var(--color-neutral-1);
  border-radius: 20px;
  height: fit-content;
  width: 100%;
  padding: 0;
  border: none;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
}

.withPadding {
  padding: 16px;
}
